<template>
  <div :class="['sharing-packets', {on: sharePacket.isState}]">
    <div class='iconfont iconguanbi' bindtap='closeShare'></div>
    <div class='line'></div>
    <div class='sharing-con' bindtap='goShare'>
      <img src='/images/red-packets.png'>
      <div class='text font-color'>
        <div>会员分享返</div>
        <div class='money'><span class='label'>￥</span>{{sharePacket.priceName}}</div>
        <div class='tip'>下单即返佣金</div>
        <div class='shareBut'>立即分享</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'share-red-packets',
    props: {
      sharePacket:{
        type:Object,
        value:{
          isState: true,
          priceName:'',
        }
      }
    },
    data() {
      return {
        value: 1,
      }
    },
    methods: {
      closeShare:function(){
        this.sharePacket.isState= true
      },
      goShare:function(){
        this.$emit('goShare');
      },
    }
  }
</script>

<style scoped>
  .sharing-packets{position:fixed;right:30px;top:800px;z-index:5;transition:all 0.3s ease-in-out 0s;opacity:1;transform: scale(0.8);}
  .sharing-packets.on{transform: scale(0);opacity:0;}
  .sharing-packets .iconfont{width:44px;height:44px;border-radius:50%;text-align:center;line-height:44px;background-color:#999;font-size:20px;color:#fff;margin:0 auto;box-sizing:border-box;padding-left:1px;}
  .sharing-packets .line{width:2px;height:40px;background-color:#999;margin:0 auto;}
  .sharing-packets .sharing-con{width:187px;height:210px;position:relative;}
  .sharing-packets .sharing-con image{width:100%;height:100%;}
  .sharing-packets .sharing-con .text{position:absolute;top:30px;font-size:20px;width:100%;text-align:center;}
  .sharing-packets .sharing-con .text .money{font-size:32px;font-weight:bold;margin-top:5px;}
  .sharing-packets .sharing-con .text .money .label{font-size:20px;}
  .sharing-packets .sharing-con .text .tip{font-size:18px;color:#999;margin-top:5px;}
  .sharing-packets .sharing-con .text .shareBut{font-size:22px;color:#fff;margin-top:18px;height:50px;line-height:50px;}
  .font-color {
    color: #fc4141!important;
  }
</style>
