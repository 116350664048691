<template>
  <div class="page">
    <div class="banner-box">
      <div class="goodsSwiper">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#FFD11A">
          <van-swipe-item  v-for="item in goodsInfo.images" :key="item.id">
            <div class="swiper-item">
              <img class="ban" :src="item.image_url">
            </div>
          </van-swipe-item>
        </van-swipe>
        
      </div>
      <div class="desc-box">
        <div class="price-box flexcenter">
          <div class="flexcenter">
            <div class="price">￥{{goodsInfo.price}}</div>
            <div class="price_qi">起</div>
          </div>
        
          <div class="yx">已销 {{goodsInfo.v_sale_number}}+</div>
        </div>
      
        <div class="title-box flexcenter">
          <div class="title1">{{goodsInfo.goods_name}}</div>
          <div class="share flexcenter" @click="goShare">
           <!-- <i class="iconfont iconfenxiang"></i> -->
          </div>
        </div>
      </div>
  
    </div>
  
    <div class="gap" v-if="goodsInfo.have_sale_attr"></div>
  
    <div class="gap"></div>
  
    <div class="details">
      <div class="title">商品详情</div>
      <div class="descption">
		<div class="content" v-html="goodsInfo.content"></div>
      </div>
    </div>
  
    <div class="bottom flexcenter">
      <div class="action-box flexcenter">
        <div @click="quickToBuy(goodsInfo)" class="right">立即购买</div>
      </div>
    </div>
    <share-red-packets :sharePacket="sharePacket" @goShare="goShare"></share-red-packets>
  </div>
</template>

<script>
  import shareRedPackets from 'components/share-red-packets'
  
  export default {
    name: 'detail',
    data() {
      return {
        goodsInfo:{},
        swiperImgWidth:'0',
        showShare:false,
        sharePacket:{
          isState:true,
          priceName:0
        },
        shareOptions: [
          { name: '微信', icon: 'wechat', openType: 'share' },
          { name: '朋友圈', icon: 'https://img01.yzcdn.cn/vant/share-sheet-wechat-moments.png', openType: 'share' }
        ],
      }
    },
    components: {
      shareRedPackets,
    },
    created() {
      let tid = this.$route.query.tid;
      if(typeof tid=='undefined'||tid<1) {
        this.$toast('产品不存在')

        setTimeout(() => {
          // this.$router.go(-1)
        }, 1000);
      }

      this.getGoodsInfo(tid);
    },
    methods: {
      /**获取商品信息 */
      getGoodsInfo(tid){
        let that = this;
        this.post(
          this.API.tourProductDetail,
          {tid:tid}
        ).then(response => {
          this.goodsInfo=response.data.goods
          this.sharePacket={
            isState:!response.data.is_promoter,
            priceName:response.data.spread_price
          }
          //html转wxml
          window.WxParse && WxParse.wxParse('description', 'html', response.data.goods.content, that, 0);
        })
      },
      /**
       * 立即购买
       */
      quickToBuy:function(data){
        if(data.isopen=='1') {
          this.$router.push({
            name: 'tourCheckout',
            query: {
              tid: data.id
            }
          })
        } else {
          this.$toast("预计" + this.goodsInfo.on_sale_time_text + "开始售卖")
        }
      },
      /**
       * 设置swiper 图片宽度
       */
      onSwiperLoad:function(e){
        const {height,width}=e.detail
        this.swiperImgWidth =(640*(width/height))
      },
      /**
       * 去分享
       */
      goShare:function(){
        this.showShare=true
      },
      /**
       * 取消分享
       */
      cancelShare:function(){
        this.showShare=false
      },
      /**
       * 选择分享的类型
       */
      selectShare:function(event){
        console.log(event.detail);
        this.cancelShare();
      },
    }
  };
</script>

<style>
	.descption .content img {
		width: 100% !important;
		display: inherit;
	}
</style>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #F5F5F5;
  }

  /*banner区*/
  .banner-box {
      /*height: 486px;*/
	  background-color: #FFFFFF;
  }
  .banner-box .goodsSwiper swiper {
      height: 600px;
  }
  .banner-box .goodsSwiper .swiper-item {
      text-align: center;
      background-color:#efefef;
  }
  .banner-box .goodsSwiper .swiper-item img {
      height: 600px;
  }
  .banner-box .desc-box {
      padding: 30px;
  }
  
  .banner-box .title-box {
      justify-content: space-between;
      font-size: 32px;
      font-weight: bold;
      color: #191919;
  }
  .banner-box .title-box .title1 {
  }
  .banner-box .title-box .share {
      font-size: 24px;
      font-weight: normal;
      margin-left: 10px;
      /* width: 100px; */
  }
  .banner-box .title-box .share .iconfont {
      margin-right: 5px;
      font-size:36px;
  }
  .banner-box .price-box {
      justify-content: space-between;
      margin-bottom: 6px;
  }
  .banner-box .price-box .yx {
      font-size: 26px;
      /* font-weight: bold; */
      color: #777777;
      padding-top: 20px;
  }
  .banner-box .price-box .price {
      margin-right: 30px;
      font-size: 56px;
      font-weight: bold;
      color: #F84F41;
      text-indent: -4px;
  }
  .banner-box .price-box .huiyuan {
      text-align: center;
      font-size: 24px;
  }
  .banner-box .price-box .huiyuan .hy1 {
      display: inline-block;
      width: 92px;
      height: 28px;
      line-height: 28px;
      background-image: linear-gradient(106deg, #464E51 0%, #454D51 100%);
      border-radius: 4px 0 0 4px;
      color: #E4DCAD;
  }
  .banner-box .price-box .huiyuan .hy2 {
      display: inline-block;
      width: 92px;
      height: 28px;
      line-height: 28px;
      background-image: linear-gradient(48deg, #FCE7BD 0%, #FFF6D4 100%);
      border-radius: 0 4px 4px 0;
      color: #4C462C;
  }
  
  .gap {
      height: 20px;
      background-color: #F5F5F5;
  }
  
  .kucun-box {
      padding: 30px;
      font-size: 28px;
      color: #191919;
  }
  .kucun-box .kc {
      margin-bottom: 30px;
  }
  .kucun-box .left {
      width: 124px;
  }
  .kucun-box .mj {
      margin-right: 10px;
      width: 120px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      border: 1px solid #F84F41;
      border-radius: 6px;
      font-size: 20px;
      color: #F84F41;
  }
  
  .otherInfo .title {
      font-size: 32px;
      font-weight: bold;
      color: #191919;
      padding:20px 30px; 
  }
  
  .details {
      padding: 30px;
      padding-bottom: 120px;
	  background-color: #FFFFFF;
	  overflow: hidden;
  }
  .details .title {
      margin-bottom: 50px;
      font-size: 32px;
      font-weight: bold;
      color: #191919;
  }
  
  .bottom {
      justify-content: space-between;
      position: fixed;
      z-index: 2;
      left: 0;
      right: 0;
      bottom: 0;
      box-sizing: border-box;
      padding: 0 30px;
      height: 120px;
      background-color: #FFFFFF;
      font-size: 20px;
      font-weight: bold;
      color: #333333;
  }
  .bottom .item {
      justify-content: center;
      flex-direction: column;
  }
  .bottom .item .iconfont {
      display: block;
      font-size: 40px;
  }
  
  .bottom .action-box {
      text-align: center;
      font-size: 32px;
      font-weight: normal;
      color: #FFFFFF;
      line-height: 92px;
  }
  
  .bottom .action-box .right {
      width: 700px;
      height: 92px;
      border-radius: 46px;
      background-color: #FCC601;
  }
  
  .badge {
      background-color:#FF0000;
      position: absolute;
      width: 30px;
      height: 30px;
      color:#FFFFFF;
      text-align: center;
      vertical-align: middle;
      border-radius: 15px;
      margin-left: 40px;
      margin-top: -55px;
  }
  
  .price_qi {
      margin-top: 10px;
      margin-left: -28px;
      font-size: 24px;
  }

</style>
